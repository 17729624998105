<template>
  <main class="unified__menu position-relative">
    <b-avatar
      @click.native="
        menuVisible = !menuVisible;
        expandCollapseApp();
      "
      src="@/assets/images/icons/unified-menu/unified-menu.svg"
      size="4em"
      id="unifiedMenu"
      class="cursor-pointer icon"
      variant="light"
    ></b-avatar>
    <b-collapse
      ref="unified_collapse"
      class="position-absolute menu__container"
      v-model="menuVisible"
      @shown="onShown"
    >
      <div class="d-flex">
        <div class="triangle position-absolute"></div>
      </div>
      <div class="menu">
        <div
          class="apps__container w-100"
          v-if="
            userAppSubscriptions && Object.keys(userAppSubscriptions).length > 0
          "
        >
          <div
            id="uniexpand"
            class="expand w-100 overflow-hidden"
            :class="gridOrder"
            style="height: 0rem; display: none; opacity: 0"
          >
            <div
              v-if="activeApps.length > 1"
              class="expand__container d-flex flex-column justify-content-start w-100"
            >
              <div
                v-for="(app, i) in activeApps"
                :key="i"
                class="app app-hover w-100 d-flex align-items-center justify-content-start"
              >
                <!-- market only -->
                <span
                  v-if="app.appId === 'market'"
                  target="_blank"
                  :title="app.organizationName"
                  class="d-flex align-items-center"
                  @click="
                    navigateToMarket(app.organizationId, authUserData._id)
                  "
                >
                  <img
                    v-if="app.organizationColor"
                    class="p-25 mx-75 cursor-pointer"
                    :src="getAppDataById(app.appId).uni_color_icon"
                    :alt="getAppDataById(appId).shortTitle"
                    width="50"
                    height="50"
                    :style="getFilterColor(app.organizationColor)"
                  />
                  <img
                    v-else
                    class="p-25 mx-75 cursor-pointer"
                    :src="getAppDataById(app.appId).icon"
                    :alt="getAppDataById(appId).shortTitle"
                    width="50"
                    height="50"
                  />
                  <span class="app__text mx-50">{{
                    app.organizationName
                  }}</span>
                </span>

                <a
                  v-else
                  :href="app.loginLink"
                  target="_blank"
                  :title="app.organizationName"
                  @click="closeUnifiedMenu"
                  class="d-flex align-items-center"
                >
                  <img
                    v-if="app.organizationColor"
                    class="p-25 mx-75 cursor-pointer"
                    :src="getAppDataById(app.appId).uni_color_icon"
                    :alt="getAppDataById(appId).shortTitle"
                    width="50"
                    height="50"
                    :style="getFilterColor(app.organizationColor)"
                  />
                  <img
                    v-else
                    class="p-25 mx-75 cursor-pointer"
                    :src="getAppDataById(app.appId).icon"
                    :alt="getAppDataById(appId).shortTitle"
                    width="50"
                    height="50"
                  />
                  <span class="app__text mx-50">{{
                    app.organizationName
                  }}</span>
                </a>
              </div>
            </div>
          </div>
          <div
            v-for="(appId, i) in Object.keys(userAppSubscriptions)"
            :key="i"
            :class="[getAppDataById(appId).id ? '' : 'd-none', 'order-' + i]"
          >
            <a
              v-if="
                (userAppSubscriptions[appId].length === 1 ||
                  appId === 'getboarded') &&
                getAppDataById(appId) !== -1 &&
                appId !== 'market'
              "
              @click="closeUnifiedMenu"
              target="_blank"
              :href="userAppSubscriptions[appId][0].loginLink"
              class="d-flex align-items-center justify-content-center"
            >
              <small
                class="d-flex apps apps-hover p-25 flex-column align-items-center justify-content-center"
              >
                <img
                  class="p-25 mx-75 cursor-pointer"
                  :src="getAppDataById(appId).icon"
                  :alt="getAppDataById(appId).shortTitle"
                  width="50"
                  height="50"
                />
                <div class="mt-25 apps__text">
                  {{ getAppDataById(appId).shortTitle }}
                </div>
              </small>
            </a>

            <div
              v-else-if="
                userAppSubscriptions[appId].length > 1 &&
                getAppDataById(appId) !== -1 &&
                appId !== 'getboarded'
              "
            >
              <small
                class="d-flex apps apps-hover p-25 flex-column align-items-center justify-content-center my-50 cursor-pointer"
                :class="{ 'apps-bg': appId === activeAppId }"
                @click="expandCollapseApp(appId, i)"
              >
                <img
                  class="p-25 mx-75 cursor-pointer"
                  :src="getAppDataById(appId).icon"
                  width="50"
                  height="50"
                  :alt="getAppDataById(appId).shortTitle"
                />
                <div class="mt-25 apps__text">
                  {{ getAppDataById(appId).shortTitle }}
                </div>
              </small>
            </div>

            <div v-else>
              <div
                v-if="appId === 'market'"
                class="w-100 d-flex align-items-center justify-content-center"
                target="_blank"
                :title="userAppSubscriptions[appId][0].organizationName"
                @click="
                  navigateToMarket(
                    userAppSubscriptions[appId][0].organizationId,
                    authUserData._id
                  )
                "
              >
                <small
                  class="d-flex apps apps-hover p-25 flex-column align-items-center justify-content-center my-50 cursor-pointer"
                  :class="{ 'apps-bg': appId === activeAppId }"
                >
                  <img
                    class="p-25 mx-75 cursor-pointer"
                    :src="getAppDataById(appId).icon"
                    :alt="getAppDataById(appId).shortTitle"
                    width="50"
                    height="50"
                  />
                  <div class="mt-25 apps__text">
                    {{ getAppDataById(appId).shortTitle }}
                  </div>
                </small>
              </div>
              <a
                v-else
                class="w-100 d-flex align-items-center"
                :href="userAppSubscriptions[appId][0].loginLink"
                target="_blank"
                @click="closeUnifiedMenu"
                :title="userAppSubscriptions[appId][0].organizationName"
              >
                <small
                  class="d-flex apps apps-hover p-25 flex-column align-items-center justify-content-center"
                >
                  <img
                    class="p-25 mx-75 cursor-pointer"
                    :src="getAppDataById(appId).icon"
                    :alt="getAppDataById(appId).shortTitle"
                    width="50"
                    height="50"
                  />
                  <div class="mt-25 apps__text">
                    {{ getAppDataById(appId).shortTitle }}
                  </div>
                </small>
              </a>
            </div>
          </div>
        </div>
        <div v-else class="py-75 px-1 apps-not-exist">
          No Apps Installed yet!
          <img
            class="mt-2"
            src="@/assets/images/icons/unified-menu/empty.svg"
            alt=""
          />
        </div>
      </div>
    </b-collapse>
  </main>
</template>

<script>
import { BAvatar, BCollapse } from "bootstrap-vue";
import apps from "@/assets/data/marketplace-list.js";
import gsap from "gsap";
import CssFilterConverter from "css-filter-converter";

export default {
  name: "UnifiedMenu",
  data() {
    return {
      apps,
      menuVisible: false,
      selectedAppId: "",
      activeApps: [],
      activeAppId: "",
      gridOrder: "",
    };
  },
  components: {
    BAvatar,
    BCollapse,
  },
  computed: {
    userData() {
      return this.$store.state.user.userData;
    },
    userAppSubscriptions() {
      return this.$store.state.user.appSubscriptions;
    },
    authUserData() {
      return this.$store.state.auth.user;
    },
    isUserLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn;
    },
  },
  created() {
    this.appSubscription();
  },
  methods: {
    expandCollapseApp(appId, appIndex) {
      if (appId && appIndex) {
        if (appId !== this.activeAppId) {
          const tl = gsap.timeline({
            defaults: {
              duration: 0.2,
              ease: "rough",
              repeat: 0,
            },
          });

          tl.fromTo(
            "#uniexpand",
            0.2,
            { opacity: 0, display: "none", height: "0rem" },
            { opacity: 0, display: "flex", height: "0rem" }
          );

          tl.fromTo(
            "#uniexpand",
            0.2,
            { opacity: 0, display: "flex", height: "0rem" },
            { opacity: 1, display: "flex", height: "auto" }
          );
          this.activeApps = this.userAppSubscriptions[appId];
          this.activeAppId = appId;

          if (appIndex > 0 && appIndex <= 2) {
            this.gridOrder = "order-3";
          } else if (appIndex > 2 && appIndex <= 5) {
            this.gridOrder = "order-6";
          } else {
            this.gridOrder = "order-12";
          }
        } else {
          const tl = gsap.timeline({
            defaults: {
              duration: 0.2,
              ease: "rough",
              repeat: 0,
            },
          });

          tl.fromTo(
            "#uniexpand",
            0.1,
            { opacity: 0, display: "flex", height: "10rem" },
            { opacity: 0, display: "none", height: "0rem" }
          );
          this.activeAppId = "";
        }
      } else {
        const tl = gsap.timeline({
          defaults: {
            duration: 0.2,
            ease: "rough",
            repeat: 0,
          },
        });

        tl.fromTo(
          "#uniexpand",
          0.1,
          { opacity: 0, display: "flex", height: "3rem" },
          { opacity: 0, display: "none", height: "0rem" }
        );
        this.activeAppId = "";
      }
    },
    async navigateToMarket(_orgId, _userId) {
      const _accessToken = localStorage.getItem("accessToken");
      await this.fetchOrgDetailsFromOrgId(_orgId, _userId, _accessToken);
      this.closeUnifiedMenu();
      this.activeAppId = "";
    },
    async fetchOrgDetailsFromOrgId(_orgId, _userId, _accessToken) {
      this.$store.dispatch("user/fetchOrganizationById", _orgId).then((res) => {
        const dynamicMarketPlaceRoute = `https://market.getboarded.com/${res.slug}?userId=${_userId}&authToken=${_accessToken}`;
        window.open(dynamicMarketPlaceRoute, "_blank");
      });
    },
    appSubscription() {
      if (this.isUserLoggedIn) {
        this.$store.dispatch(
          "user/fetchUserAppSubscriptions",
          this.userData._id
        );
      }
    },
    getAppDataById(id) {
      const appIndex = this.apps.findIndex((app) => {
        return app.id === id;
      });

      if (appIndex !== -1) return this.apps[appIndex];
      else return appIndex;
    },
    onShown() {
      document.body.addEventListener("click", this.onClickOutside);
    },
    closeUnifiedMenu() {
      document.getElementById("unifiedMenu").click();
    },
    onClickOutside(event) {
      // Check if click event target is outside of the b-collapse element
      if (!this.$refs.unified_collapse.$el.contains(event.target)) {
        this.menuVisible = false;
        document.body.removeEventListener("click", this.onClickOutside);
      }
    },
    getFilterColor(hexColor) {
      const result = CssFilterConverter.hexToFilter(hexColor);
      return `filter: ${result.color}`;
    },
  },
  beforeDestroy() {
    // Remove click event from body when component is destroyed
    document.body.removeEventListener("click", this.onClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.unified__menu {
  .icon {
    background: transparent;
  }
  .menu__container {
    background: #ffffff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11);
    max-width: 25em;
    z-index: 1000;
    width: max-content;
    right: 60%;
    transform: translate(12.5%, 0);
    border-radius: 8px;
    margin-top: 8px;
    .triangle {
      right: 2.2em;
      top: -1em;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 15px solid #ffffff;
    }

    small {
      z-index: +1;
    }

    .menu {
      padding: 1.25rem 1rem;
      .apps__container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        .apps {
          margin: 0.5rem;
          border-radius: 10px;
          &__text {
            color: #848d9c;
            font-weight: 500;
            font-size: 1rem;
          }
        }
        .apps-bg {
          background-color: #f1f5f9;
        }
        .apps-hover:hover {
          background-color: #f1f5f9;
        }
        .expand {
          background-color: #f1f5f9;
          border-radius: 1.5rem;
          grid-column: span 3 / span 3;
          margin: 0.5rem 0;
          gap: 0.25rem;
          &__container {
            gap: 0.5rem;
            padding: 0.5rem;
            .app {
              border-color: transparent;
              padding: 0.5rem;
              border-radius: 1rem;
              border-style: solid;
              border-width: 2px;
              transition-property: all;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              &__text {
                color: #848d9c;
                font-weight: 500;
                font-size: 1rem;
              }
            }
            .app-hover:hover {
              background-color: #ffffff;
            }
          }
        }
      }

      .apps-not-exist {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        align-items: center;
        font-size: 1.15em;
      }
    }
  }
}
</style>
