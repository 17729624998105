<template>
  <div
    class="w-100 h-100 padding--x invite-advisor__container py-1 pt-md-3 pb-md-1"
    :class="isUserLoggedIn ? '' : 'grey-out'"
  >
    <b-row class="flex-column mt-1" id="advisor-reviews">
      <h2 class="common__heading">Build your counsel</h2>
      <h4 class="common__subheading">
        A 360° assessment that helps you map your strengths and development
        areas
      </h4>
    </b-row>
    <section class="common__card w-100">
      <validation-observer
        ref="simpleRules"
        class="invite-advisor__input-field"
      >
        <b-row>
          <b-col md="10" xl="11" cols="12" class="pl-0">
            <b-row>
              <b-col class="my-1 pr-0 pr-md-1" sm="12" md="3" xl="3">
                <h5>Email Address</h5>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-input-group size="lg">
                    <b-form-input
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      type="email"
                      placeholder="Email address"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col class="my-1 pr-0 pr-md-1" sm="12" md="3" xl="3">
                <h5>First Name</h5>
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-input-group size="lg">
                    <b-form-input
                      style="text-transform: capitalize"
                      v-model="firstName"
                      :state="errors.length > 0 ? false : null"
                      placeholder="First Name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col class="my-1 pr-0 pr-md-1" sm="12" md="3" xl="3">
                <h5>Last Name</h5>
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-input-group size="lg">
                    <b-form-input
                      style="text-transform: capitalize"
                      v-model="lastName"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Last Name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col class="my-1 pr-0 pr-md-1" sm="12" md="3" xl="3">
                <h5>Relationship</h5>
                <validation-provider
                  #default="{ errors }"
                  name="Relationship"
                  rules="required"
                >
                  <!-- <b-form-select
                    v-model="relationship"
                    :options="options"
                    class="my-select"
                    :class="errors[0] ? 'is-invalid' : ''"
                    size="lg"
                  /> -->

                  <b-form-select
                    v-model="relationship"
                    :options="options"
                    :value="relationship.text"
                    :class="errors[0] ? 'is-invalid' : ''"
                    size="lg"
                  >
                    <template #first>
                      <option value="">Select an option</option>
                    </template>
                  </b-form-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="my-1 mt-2" md="2" xl="1" sm="12">
            <div
              type="submit"
              @click="inviteGuardian"
              class="w-100 text-center cursor-pointer font-weight-bolder invite-advisor__input-field__send-btn custom-btn"
            >
              Send
            </div>
          </b-col>
        </b-row>
      </validation-observer>
      <!-- loader -->
      <b-skeleton-table
        v-if="isLoading"
        :rows="5"
        :columns="4"
        :table-props="{
          bordered: true,
          striped: true,
        }"
      ></b-skeleton-table>
      <!-- invited advisor table  -->
      <vue-perfect-scrollbar
        v-else-if="!isLoading && advisorsList && advisorsList.length > 0"
        class="invite-advisor__table mt-50 mt-md-75"
        :class="`invite-advisor__table-border-${currentTheme}`"
      >
        <table>
          <thead>
            <tr
              class="px-2 py-50 invite-advisor__table--head"
              :class="`table__head-row-${currentTheme}`"
            >
              <th class="pl-2 invite-advisor__table__col-1">Name</th>
              <th>Relationship</th>
              <th>Date</th>
              <th>Status</th>
              <th></th>
              <!-- <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(advisor, index) in advisorsList" :key="index">
              <td class="invite-advisor__table__col-1">
                <b-col
                  cols="4"
                  class="d-flex align-items-center justify-content-start"
                >
                  <b-avatar
                    variant="danger"
                    size="lg"
                    :src="`https://hostedboringavatars.vercel.app/api/beam?name=${avatarNameGenerator(
                      advisor.firstName,
                      advisor.lastName
                    )}`"
                    class="avatar-border-2"
                  >
                  </b-avatar>
                  <div class="mx-1 d-flex flex-column">
                    <h5 class="text text-capitalize">
                      {{ advisor.firstName }} {{ advisor.lastName }}
                    </h5>
                    <h5 class="main-text">{{ advisor.email }}</h5>
                  </div>
                </b-col>
              </td>
              <td class="text-capitalize">{{ advisor.relationship }}</td>
              <td v-if="advisor.createdAt">
                {{ new Date(advisor.createdAt).toISOString().substring(0, 10) }}
              </td>
              <td v-else></td>
              <td
                class="text-capitalize invite-advisor__table__status"
                :class="
                  advisor.endorsementStatus
                    ? advisor.endorsementStatus.toLowerCase()
                    : 'pending'
                "
              >
                {{
                  advisor.endorsementStatus
                    ? advisor.endorsementStatus.toLowerCase()
                    : "pending"
                }}
              </td>
              <td
                class="d-flex w-50"
                v-if="
                  advisor.endorsementStatus &&
                  advisor.endorsementStatus.toLowerCase() === 'pending'
                "
              >
                <!-- Copy link -->
                <div
                  @click="
                    copyEndorsementLink(
                      `${constants.url}/feedback?token=${
                        advisor.token
                      }&sender=${encodeURIComponent(userData.firstName)}`,
                      advisor.token
                    )
                  "
                  class="re-send-btn invite-advisor__table__resend-btn cursor-pointer text-center"
                >
                  <feather-icon
                    v-b-tooltip.hover.top="
                      linkCopied && copiedToken === advisor.token
                        ? 'Link copied'
                        : 'Copy endorsement link'
                    "
                    icon="CopyIcon"
                    size="20"
                    class="text-center cursor-pointer text-secondary"
                  />
                </div>
                <!-- Re-Send -->
                <div
                  @click="resendInvite(advisor)"
                  class="re-send-btn invite-advisor__table__resend-btn cursor-pointer text-center"
                >
                  <feather-icon
                    v-b-tooltip.hover.top="'Re-Send endorsement email'"
                    icon="SendIcon"
                    size="20"
                    class="text-center cursor-pointer text-secondary"
                  />
                </div>
              </td>
            </tr>
          </tbody>
          <!-- <tbody
            v-else
            class="common__subheading w-100 text-center position-absolute mt-1 font-weight-bold"
          >
            Request feedback from someone you know to reveal your strengths.
          </tbody> -->
        </table>
      </vue-perfect-scrollbar>
    </section>

    <small id="talent-report">
      <div
        v-if="advisorsList.length === 0"
        class="d-flex justify-content-center align-items-center mb-1 mb-md-2 mb-lg-3"
      >
        <div
          class="feedbackbox feedbackbox__text px-50 py-75 px-md-1 px-lg-2 text-center"
        >
          Request feedback from someone you know to reveal your strengths.
        </div>
      </div>
      <div
        v-if="
          advisorsList.length > 0 &&
          advisorsList.length < 3 &&
          completedEndorsements === 0
        "
        class="d-flex justify-content-center align-items-center mb-1 mb-md-2 mb-lg-3"
      >
        <div
          class="feedbackbox feedbackbox__text px-50 py-75 px-md-1 px-lg-2 text-center"
        >
          The scroll is waiting... invite {{ 3 - advisorsList.length }} more
          counsel to give review and reveal your strengths.
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center mb-1 mb-md-2 mb-lg-3"
        v-if="advisorsList.length >= 3 && completedEndorsements < 3"
      >
        <div
          class="feedbackbox feedbackbox__text px-50 py-75 px-md-1 px-lg-2 text-center"
        >
          You have
          {{ completedEndorsements > 0 ? completedEndorsements : "no" }}
          {{ completedEndorsements === 1 ? "feedback" : "feedbacks" }}
          received… Wait for {{ 3 - completedEndorsements }}
          {{ 3 - completedEndorsements === 1 ? "advisor" : "advisors" }} to give
          review and reveal your strengths.
        </div>
      </div>
      <!-- Reveal Strength Report -->
      <div
        v-else-if="completedEndorsements >= 3"
        class="py-2 d-flex justify-content-center align-items-center position-relative"
        id="canvas"
      >
        <img
          class="position-absolute"
          :class="clicked ? 'popbird__down ' : 'popbird__up'"
          :src="require(`@/assets/images/Bird.svg`)"
          alt="Bird"
        />
        <div class="cursor-pointer common__btn" @click="revealStrength">
          <p class="m-0 common__btn__text">Reveal your strengths</p>
        </div>
      </div>
    </small>
  </div>
</template>
<script>
import confetti from "canvas-confetti";
import {
  BCol,
  BRow,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BAvatar,
  VBTooltip,
  BSkeletonTable,
  //   BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import notificationMixin from "@/mixins/notificationMixin.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import constants from "@/constants";
export default {
  name: "EndorsementModal",
  mixins: [notificationMixin],
  components: {
    BCol,
    BRow,
    BFormInput,
    BInputGroup,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BAvatar,
    VuePerfectScrollbar,
    BSkeletonTable,
  },
  data() {
    return {
      clicked: false,
      isLoading: true,
      options: [
        { value: "manager", text: "Manager" },
        { value: "direct-report", text: "Direct Report" },
        { value: "peer", text: "Peer" },
        { value: "other", text: "Other" },
      ],
      userEmail: "",
      firstName: "",
      lastName: "",
      relationship: "",
      required,
      email,
      advisorsList: [],
      linkCopied: false,
      copiedToken: "",
      constants,
      completedEndorsements: 0,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    currentTheme() {
      return this.$store.state.user.themeType;
    },
    userData() {
      return this.$store.state.user.userData;
    },
    avatarNameGenerator() {
      return this.$store.state.user.avatarNameGenerator;
    },
    isUserLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn;
    },
  },
  watch: {
    completedEndorsements(newVal) {
      newVal >= 3 ? this.getStrengthProfile() : "";
    },
  },
  methods: {
    inviteGuardian() {
      const advisorEmailList = this.advisorsList.map((advisor) => {
        return advisor.email;
      });
      if (advisorEmailList && advisorEmailList.includes(this.userEmail)) {
        let notificationDetail = {
          message: this.messages.user_already_added.text(this.userEmail),
          variant: "danger",
        };
        this.showToast(notificationDetail);
        return;
      }

      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let { userEmail, firstName, lastName, relationship } = this;
          if (!this.userData.firstName) {
            let notificationDetail = {
              message: this.messages.error.username_missing,
              variant: "danger",
            };
            return this.showToast(notificationDetail);
          }

          const fName= firstName.replace(/([A-Z])/g, " $1");
          const lName = lastName.replace(/([A-Z])/g, " $1");
          firstName = fName.charAt(0).toUpperCase() + fName.slice(1);
          lastName = lName.charAt(0).toUpperCase() + lName.slice(1);

          const payload = {
            sender: {
              gtbrddUserId: this.userData._id,
              firstName: firstName
            },
            email: userEmail,
            firstName: firstName,
            lastName: lastName,
            relationship: relationship,
            endorsementStatus: "PENDING",
            createdAt: new Date(),
          };

          this.$store
            .dispatch("user/addGuardian", payload)
            .then((res) => {
              this.advisorsList.push({ ...payload, ...res });

              let notificationDetail = {
                message: this.messages.inviteGuardian.text(
                  `${firstName} ${lastName}`
                ),
                variant: "success",
              };

              this.showToast(notificationDetail);

              this.userEmail = "";
              this.firstName = "";
              this.lastName = "";
              this.relationship = "";
              this.$refs.simpleRules.reset();
            })
            .catch(() => {
              let notificationDetail = {
                message: this.messages.error.unknown_error,
                variant: "danger",
              };
              this.showToast(notificationDetail);
            });
        }
      });
    },
    resendInvite(advisor) {
      this.$store
        .dispatch("user/reSendGuardianInvite", advisor._id)
        .then((res) => {
          console.log(res);
          let notificationDetail = {
            message: this.messages.resendInvitation.text,
            variant: "success",
          };
          this.showToast(notificationDetail);
        })
        .catch((err) => {
          console.log(err);
          let notificationDetail = {
            message: this.messages.error.unknown_error,
            variant: "danger",
          };
          this.showToast(notificationDetail);
        });
    },
    getGuardian() {
      if (this.userData && this.userData._id) {
        this.isLoading = true;
        this.$store
          .dispatch("user/getGuardian", this.userData)
          .then((res) => {
            if (res.data) {
              this.advisorsList = res.data;
              for (let i = 0; i < res.data.length; i++) {
                if (
                  res.data[i].endorsementStatus.toLowerCase() === "completed"
                ) {
                  this.completedEndorsements = this.completedEndorsements + 1;
                }
              }
            }
          })
          .catch(() => {
            // console.log(err);
            return;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    },
    copyEndorsementLink(link, token) {
      this.linkCopied = false;
      this.copiedToken = token;
      navigator.clipboard.writeText(link);
      this.linkCopied = true;
    },
    revealStrength() {
      this.clicked = !this.clicked;
      this.getStrengthProfile();

      confetti({
        particleCount: 120,
        spread: 150,
        origin: {
          y: 0.6,
        },
      });

      setTimeout(() => {
        this.$store.commit("modals/OPEN_REPORT_POPUP", true);
        this.clicked = false;
      }, 1500);

      setTimeout(() => {
        confetti.reset();
      }, 8000); // stop confetti after 8 seconds
    },
    getStrengthProfile() {
      this.$store
        .dispatch("user/getStrengthProfile", this.userData._id)
        .then((res) => {
          if (res?.data?.url) {
            this.$store.commit("user/SET_REPORT_URL", res.data.url);
            this.$store.commit("user/IS_TALENT_REPORT_GENERATED", true);
          }
        })
        .catch(() => {
          return;
        });
    },
  },
  created() {
    this.getGuardian();
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.pending {
  // background: #232326;
  color: #f8be17;
}

table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 0.5rem;
}

th {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #f1f1f1;
}

.invite-advisor {
  &__container {
    background: $gray-100;
  }

  border-radius: 10px;
  &__heading {
    &--1 {
      font-size: 2.5em;
    }
  }

  &__input-field {
    &__send-btn {
      margin-top: 1.1em;
      padding: 0.5em;
      color: #ffffff;
      border-radius: 6px;
      font-size: 1.25em;
    }
  }

  &__table {
    height: 40vh;
    margin: 0 1em;

    overflow-y: scroll;

    &__col-1 {
      width: 40%;
    }

    &-border {
      &-dark {
        border: 1px solid #232326;
      }

      &-light {
        border: 1px solid #f1f1f1;
      }
    }

    border-radius: 10px;
    &__resend-btn {
      padding: 0.75em;
      font-weight: 500;
      width: 50%;
    }
  }
}

.completed {
  color: #00c14e;
}

.delete-icon:hover {
  color: #f25f25;
  scale: 1.1;
}

.custom-select-lg:focus,
.input-group-lg::v-deep input:focus {
  border-color: var(--app-theme-color) !important;
}

.my-select .dropdown-menu > .dropdown-item:hover {
  background-color: #e9ecef;
}
.popbird {
  &__up {
    width: 6em;
    top: -3em;
    transition: all 0.3s ease-in-out;
  }
  &__down {
    width: 7.5em;
    top: -4.5em;
    transition: all 0.3s ease-in-out;
  }
}

.common__btn {
  z-index: 1;
}

.feedbackbox {
  background-color: #ffffff;
  border-radius: 2em;
  padding: 1em 1.5em;

  &__text {
    font-size: 1.2em;
    color: #667085;
    font-weight: 600;
  }
}

@media only screen and (max-width: 1400px) {
  .endorsement {
    &__input-field {
      &__send-btn {
        margin-top: 1em;
      }
    }
    &__table {
      &__resend-btn {
        width: 75%;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  table {
    width: 130%;
  }

  .md-pd {
    padding: 1.5em !important;
  }

  .endorsement {
    width: 95%;

    &__table {
      overflow: scroll !important;

      &--head,
      &--user-details {
        width: 135%;
      }
    }
  }

  hr {
    width: 125%;
  }

  .feedbackbox {
    padding: 1em 0.75em;
    &__text {
      font-size: 1.1em;
    }
  }
}

@media only screen and (max-width: 768px) {
  // .col-sm-12 {
  //   padding-right: 0;
  // }
  .invite-advisor {
    &__input-field {
      &__send-btn {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  .feedbackbox {
    &__text {
      font-size: 1em;
    }
  }
}
</style>
