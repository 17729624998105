var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-100 padding--x invite-advisor__container py-1 pt-md-3 pb-md-1",class:_vm.isUserLoggedIn ? '' : 'grey-out'},[_c('b-row',{staticClass:"flex-column mt-1",attrs:{"id":"advisor-reviews"}},[_c('h2',{staticClass:"common__heading"},[_vm._v("Build your counsel")]),_c('h4',{staticClass:"common__subheading"},[_vm._v(" A 360° assessment that helps you map your strengths and development areas ")])]),_c('section',{staticClass:"common__card w-100"},[_c('validation-observer',{ref:"simpleRules",staticClass:"invite-advisor__input-field"},[_c('b-row',[_c('b-col',{staticClass:"pl-0",attrs:{"md":"10","xl":"11","cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"my-1 pr-0 pr-md-1",attrs:{"sm":"12","md":"3","xl":"3"}},[_c('h5',[_vm._v("Email Address")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"lg"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"email","placeholder":"Email address"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"my-1 pr-0 pr-md-1",attrs:{"sm":"12","md":"3","xl":"3"}},[_c('h5',[_vm._v("First Name")]),_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"lg"}},[_c('b-form-input',{staticStyle:{"text-transform":"capitalize"},attrs:{"state":errors.length > 0 ? false : null,"placeholder":"First Name"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"my-1 pr-0 pr-md-1",attrs:{"sm":"12","md":"3","xl":"3"}},[_c('h5',[_vm._v("Last Name")]),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"lg"}},[_c('b-form-input',{staticStyle:{"text-transform":"capitalize"},attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Last Name"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"my-1 pr-0 pr-md-1",attrs:{"sm":"12","md":"3","xl":"3"}},[_c('h5',[_vm._v("Relationship")]),_c('validation-provider',{attrs:{"name":"Relationship","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] ? 'is-invalid' : '',attrs:{"options":_vm.options,"value":_vm.relationship.text,"size":"lg"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"value":""}},[_vm._v("Select an option")])]},proxy:true}],null,true),model:{value:(_vm.relationship),callback:function ($$v) {_vm.relationship=$$v},expression:"relationship"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"my-1 mt-2",attrs:{"md":"2","xl":"1","sm":"12"}},[_c('div',{staticClass:"w-100 text-center cursor-pointer font-weight-bolder invite-advisor__input-field__send-btn custom-btn",attrs:{"type":"submit"},on:{"click":_vm.inviteGuardian}},[_vm._v(" Send ")])])],1)],1),(_vm.isLoading)?_c('b-skeleton-table',{attrs:{"rows":5,"columns":4,"table-props":{
        bordered: true,
        striped: true,
      }}}):(!_vm.isLoading && _vm.advisorsList && _vm.advisorsList.length > 0)?_c('vue-perfect-scrollbar',{staticClass:"invite-advisor__table mt-50 mt-md-75",class:("invite-advisor__table-border-" + _vm.currentTheme)},[_c('table',[_c('thead',[_c('tr',{staticClass:"px-2 py-50 invite-advisor__table--head",class:("table__head-row-" + _vm.currentTheme)},[_c('th',{staticClass:"pl-2 invite-advisor__table__col-1"},[_vm._v("Name")]),_c('th',[_vm._v("Relationship")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Status")]),_c('th')])]),_c('tbody',_vm._l((_vm.advisorsList),function(advisor,index){return _c('tr',{key:index},[_c('td',{staticClass:"invite-advisor__table__col-1"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"cols":"4"}},[_c('b-avatar',{staticClass:"avatar-border-2",attrs:{"variant":"danger","size":"lg","src":("https://hostedboringavatars.vercel.app/api/beam?name=" + (_vm.avatarNameGenerator(
                    advisor.firstName,
                    advisor.lastName
                  )))}}),_c('div',{staticClass:"mx-1 d-flex flex-column"},[_c('h5',{staticClass:"text text-capitalize"},[_vm._v(" "+_vm._s(advisor.firstName)+" "+_vm._s(advisor.lastName)+" ")]),_c('h5',{staticClass:"main-text"},[_vm._v(_vm._s(advisor.email))])])],1)],1),_c('td',{staticClass:"text-capitalize"},[_vm._v(_vm._s(advisor.relationship))]),(advisor.createdAt)?_c('td',[_vm._v(" "+_vm._s(new Date(advisor.createdAt).toISOString().substring(0, 10))+" ")]):_c('td'),_c('td',{staticClass:"text-capitalize invite-advisor__table__status",class:advisor.endorsementStatus
                  ? advisor.endorsementStatus.toLowerCase()
                  : 'pending'},[_vm._v(" "+_vm._s(advisor.endorsementStatus ? advisor.endorsementStatus.toLowerCase() : "pending")+" ")]),(
                advisor.endorsementStatus &&
                advisor.endorsementStatus.toLowerCase() === 'pending'
              )?_c('td',{staticClass:"d-flex w-50"},[_c('div',{staticClass:"re-send-btn invite-advisor__table__resend-btn cursor-pointer text-center",on:{"click":function($event){_vm.copyEndorsementLink(
                    ((_vm.constants.url) + "/feedback?token=" + (advisor.token) + "&sender=" + (encodeURIComponent(_vm.userData.firstName))),
                    advisor.token
                  )}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                    _vm.linkCopied && _vm.copiedToken === advisor.token
                      ? 'Link copied'
                      : 'Copy endorsement link'
                  ),expression:"\n                    linkCopied && copiedToken === advisor.token\n                      ? 'Link copied'\n                      : 'Copy endorsement link'\n                  ",modifiers:{"hover":true,"top":true}}],staticClass:"text-center cursor-pointer text-secondary",attrs:{"icon":"CopyIcon","size":"20"}})],1),_c('div',{staticClass:"re-send-btn invite-advisor__table__resend-btn cursor-pointer text-center",on:{"click":function($event){return _vm.resendInvite(advisor)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Re-Send endorsement email'),expression:"'Re-Send endorsement email'",modifiers:{"hover":true,"top":true}}],staticClass:"text-center cursor-pointer text-secondary",attrs:{"icon":"SendIcon","size":"20"}})],1)]):_vm._e()])}),0)])]):_vm._e()],1),_c('small',{attrs:{"id":"talent-report"}},[(_vm.advisorsList.length === 0)?_c('div',{staticClass:"d-flex justify-content-center align-items-center mb-1 mb-md-2 mb-lg-3"},[_c('div',{staticClass:"feedbackbox feedbackbox__text px-50 py-75 px-md-1 px-lg-2 text-center"},[_vm._v(" Request feedback from someone you know to reveal your strengths. ")])]):_vm._e(),(
        _vm.advisorsList.length > 0 &&
        _vm.advisorsList.length < 3 &&
        _vm.completedEndorsements === 0
      )?_c('div',{staticClass:"d-flex justify-content-center align-items-center mb-1 mb-md-2 mb-lg-3"},[_c('div',{staticClass:"feedbackbox feedbackbox__text px-50 py-75 px-md-1 px-lg-2 text-center"},[_vm._v(" The scroll is waiting... invite "+_vm._s(3 - _vm.advisorsList.length)+" more counsel to give review and reveal your strengths. ")])]):_vm._e(),(_vm.advisorsList.length >= 3 && _vm.completedEndorsements < 3)?_c('div',{staticClass:"d-flex justify-content-center align-items-center mb-1 mb-md-2 mb-lg-3"},[_c('div',{staticClass:"feedbackbox feedbackbox__text px-50 py-75 px-md-1 px-lg-2 text-center"},[_vm._v(" You have "+_vm._s(_vm.completedEndorsements > 0 ? _vm.completedEndorsements : "no")+" "+_vm._s(_vm.completedEndorsements === 1 ? "feedback" : "feedbacks")+" received… Wait for "+_vm._s(3 - _vm.completedEndorsements)+" "+_vm._s(3 - _vm.completedEndorsements === 1 ? "advisor" : "advisors")+" to give review and reveal your strengths. ")])]):(_vm.completedEndorsements >= 3)?_c('div',{staticClass:"py-2 d-flex justify-content-center align-items-center position-relative",attrs:{"id":"canvas"}},[_c('img',{staticClass:"position-absolute",class:_vm.clicked ? 'popbird__down ' : 'popbird__up',attrs:{"src":require("@/assets/images/Bird.svg"),"alt":"Bird"}}),_c('div',{staticClass:"cursor-pointer common__btn",on:{"click":_vm.revealStrength}},[_c('p',{staticClass:"m-0 common__btn__text"},[_vm._v("Reveal your strengths")])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }